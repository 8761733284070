@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';


.header-tab-skeleton {
  height: 90px;
  padding: 33px 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: $md) {
    width: 608px;
    margin: 0 auto;
    padding: 0;
    position: relative;
    gap: 93px;
  }

  @media (max-width: $sm) {
    height: 50px;
    width: 100%;
    position: relative;
    max-width: 342px;
  }
  &__stores {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    width: 150px;
  }

  &__hamburger {
    width: 150px;
    text-align: right;
  }
}
