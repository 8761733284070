@import "../../../../styles/mixins";
@import "../../../../styles/variables";

.hamburger-drawer-container {
  padding: 60px 23px 0 29px;
  &__user-details {
    margin: 19px 0;
    &__user {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      h4 {
        font-size: 16px;
        font-weight: 500;
        color: #000;
      }
      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
    }
    &__basic-details {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 21, 41, 0.08);
      padding-bottom: 20px;
      position: relative;
      &__text {
        height: 50px;
        width: 50px;
        background-color: #fabb05;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        font-size: 18px;
        position: relative;
        margin-right: 16px;
        padding: 0 16px;
        @include rtl-styles{
          margin-right: 0;
          margin-left: 16px;
        }
      }
      &__green-tick {
        position: absolute;
        top: 33px;
        left: 33px;
        @include rtl-styles{
          left: 0;
          right: 33px;
        }
        img {
          height: 16px !important;
          width: 16px !important;
          object-fit: contain;
        }
      }
      &__name-email {
        h4 {
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
        }
        h3 {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
  &__sign-out-btn {
    button {
      height: 35px;
      text-transform: capitalize;
      font-size: 16px;
      color: $primary-button;
      border: solid 1px #c4bfe1;
    }
  }
}
