@import "../../../../styles/mixins";
@import "../../../../styles/variables";
.order-summary-container {
  width: 383px;
  height: 328px;
  flex-grow: 0;
  padding: 26px 30px 30px;
  border-radius: 14px;
  background-color: $brand-card-wrapper-color;

  &__heading {
    height: 20px;
    flex-grow: 0;
    margin: 0 0 20px 0;
    font-size: 16px;
    font-weight: 600;
  }

  &__inner-container {
    padding: 20px 0 26px 0;
    display: flex;
    flex-direction: column;
    gap: 17px;

    &__totalitem-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__items {
        height: 17px;
        flex-grow: 0;
        font-size: 12px;
      }

      &__qty {
        height: 17px;
        font-size: 12px;
        font-weight: 600;
      }
    }

    &__container {
      display: flex;
      justify-content: space-between;

      &__total-gift-value {
        height: 17px;
        flex-grow: 0;
        font-size: 12px;
      }

      &__price {
        height: 17px;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

  &__total {
    display: flex;
    justify-content: space-between;
    height: 20px;
    font-size: 16px;
    font-weight: 600;
  }

  &__button-div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 25px;
  }
}

