@import "../../../../styles/mixins";
@import "../../../../styles/variables";

.store-selector-container {
  max-width: 302px;
  max-height: 500px;
  overflow-y: scroll;
  left: 0;
  width: 100%;
  position: absolute;
  top: 70px;
  z-index: 1000;
  background-color: $white;
  border-radius: 10px;
  padding: 0 16px;
  box-shadow: 0 4px 27px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e2e6e8;
  @include rtl-styles{
    right: 0;
  }

  @media (max-width: 688px) {
    max-width: 294px;
    top: 57px;
  }

  &__country-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
    border-bottom: 0.5px solid #d8d8d8;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &__left {
      &__wrapper {
        display: flex;
        gap: 16px;
        align-items: center;
        img {
          height: 20px;
          width: 24px;
          border-radius: 4px;
          @media (min-width: $md) {
            width: 20px;
            height: 16px;
          }
        }
        h4 {
          font-size: 14px;
          color: #3a1a5f;
          max-width: 200px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

        }
      }
    }
    &__right {
      padding-right: 20px;
      img {
        width: 13px;
        height: 10px;
      }
    }
  }
}

.is-web {
  min-width: 270px;
  left: -210px;
  top: 34px;
  max-height: 500px;
  overflow-y: scroll;
  @include rtl-styles {
    right: -210px;
  }
}
