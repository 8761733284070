@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.helpline-container {
  background-color: #f6f8f9;
  padding: 56px 30px;
  height: 100vh;
  &-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 14px;

    img {
      height: 20px;
      width: 20px;
    }
    
    h4 {
      font-size: 20px;
      font-family: Cairo;
      font-weight: bold;
      color: #000;
      margin: 0;
    }
  }
  &-phone-details {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &-single {
      display: flex;
      align-items: center;
      gap:15px;
      max-width: 237px;
      width: 100%;
      height: 50px;
      background-color: $white;
      border-radius: 50px;
      border: 1px solid #e4e4e4;
      padding: 8px 21px;

      &-left {
        img {
          height: 14px;
          width: 19px;
        }
      }
      &-right {
        direction: ltr;
        &-top {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 1px;
        }
        &-bottom {
          font-size: 12px;
        }
      }
    }
  }
}
