@import "../../../../styles/mixins";
@import "../../../../styles/variables";

.auth-required-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;

  &__contents {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__imoji-container {
      height: 206px;
      width: 206px;
      border-radius: 50%;
      background-color: #f4f4f4;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
      img {
        height: 80px;
        width: 80px;
        object-fit: contain;
      }
    }
    &__text-container {
      display: flex;
      flex-direction: column;
      gap: 17px;
      align-items: center;
      margin-bottom: 61px;
      p {
        font-family: Roboto;
        font-size: 14px;
        color: #8e8e8e;
        @media (max-width: $sm) {
          max-width: 250px;
          text-align: center;
        }
      }
      h3 {
        font-family: Roboto;
        font-size: 24px;
        font-weight: 600;
        color: #1f1f1f;
      }
    }
    &__back-button {
      button {
        width: 314px;
        height: 50px;
        border-radius: 12px;
        text-transform: capitalize;
      }
    }
  }
}
