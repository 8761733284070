@import "../../../styles/mixins";
@import "../../../styles/variables";

.dropdown {
  cursor: default;
  position: absolute;
  width: 373px;
  height: 267px;
  border: 1px solid rgba(0, 21, 41, 0.08);
  border-radius: 12px;
  background-color: $white;
  inset: 26px -363px;
  z-index: 999;

  &__top {
    margin: 12px 6px 0 5px;
    &__header {
      background-color: $primary-background;
      padding: 8px;
      text-align: center;
      font-size: 12px;
      border-radius: 8px;
    }
  }
  &__middle {
    margin: 19px 20px 21px 20px;
    &__user-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      &__text {
        font-size: 16px;
        font-weight: 500;
      }
      &__edit-icon {
        img {
          height: 24px !important;
          width: 24px !important;
        }
      }
    }
    &__details-box {
      display: flex;
      @include rtl-styles {
        margin-bottom: 10px;
      }

      &__rounded-box {
        height: 50px;
        width: 50px;
        background-color: #fabb05;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        font-size: 18px;
        position: relative;
        margin-right: 16px;
        @include rtl-styles {
          margin-left: 16px;
          margin-right: 0;
        }
        &__green-tick {
          position: absolute;
          top: 31px;
          left: 33px;
          img {
            height: 16px !important;
            width: 16px !important;
            object-fit: contain;
          }
        }
      }
      &__details {
        h3 {
          font-size: 14px;
          font-weight: 500;
        }
        h4 {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    &__switch-button {
      margin-left: 70px;
      button {
        height: 30px;
        background-color: $white;
        color: $primary-button;
        font-size: 12px;
        border: 1px solid $primary-button;
        text-transform: capitalize;

        &:hover {
          background-color: #fff;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    position: relative;
    @include rtl-styles {
      justify-content: flex-end;
    }

    &__change-password {
      padding: 22px 0 0 20px;
      font-size: 12px;
      color: #332879;
      cursor: pointer;
      font-family: Roboto;
      font-weight: 500;
    }
    // rest positioning styles at global.scss line number 92.
  }
}
hr {
  background-color: rgba(0, 21, 41, 0.08);
}
