@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.category-selector-container {
  max-width: 404px;
  max-height: 663px;
  overflow: auto;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 27px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e2e6e8;
  background-color: $white;
  position: absolute;
  top: 68px;
  right: 0px;
  z-index: 999;
  padding: 0 16px;
  word-break: break-all;
  @include rtl-styles{
    top: 80px;
  }
  

  &__category-section {
    padding: 27px 0;
    border-bottom: 0.5px solid #f3f4f6;
    font-size: 14px;
    color: #3a1a5f;
    line-height: 1.29;
    cursor: pointer;
    display: flex;
    gap: 24px;
    img{
      height: 22px;
      width: 22px;
    }
  }
}
