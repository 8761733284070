@import "../../../styles/mixins";
@import "../../../styles/variables";

.snackbar-container {
  border-radius: 10px;
  border: solid 1px #00a651;
  background-color: $white;
  width: 450px;
  height: 90px;
  display: flex;
  align-items: center;

  @include for-mobile-phone-only {
    width: 314px;
  }

  .success-logo {
    width: 60px;
    height: 60px;
    margin-inline: 16px;

    @include for-mobile-phone-only {
      margin: 15px;
    }

    img {
      width: 100%;
      object-fit: contain;
    }
  }
  
  .success-texts {
    @include for-mobile-phone-only {
      max-width: 211px;
    }

    .success-text-head {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;

      @include for-mobile-phone-only {
        font-size: 14px;
        font-weight: 500;
      }
    }
    
    .success-text-content {
      margin: 0;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.58;
      letter-spacing: normal;

      @include for-mobile-phone-only {
        font-size: 10px;
      }
    }
  }
}
