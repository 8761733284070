@import "../../../../styles/mixins";
@import "../../../../styles/variables";

.minor-header-container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 23px 90px;

  @media (max-width: $md) {
    height: 90px;
  }

  @media (max-width: $sm) {
    height: 50px;
    padding: 0 16px;
  }
  
  &__wrapper {
    width: 1260px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $md) {
      max-width: 606px;
      margin: 0 auto;
    }
    @media (max-width: $sm) {
      max-width: 341px;
      margin: 0 auto;
    }
    &__left {
      img {
        height: 55px;
        width: 83px;
        object-fit: contain;
        @include for-tablet-device-only {
          width: 40px;
          height: 27px;
        }
      }
    }

    &__right {
      &__hamburger {
        cursor: pointer;
        img {
          width: 16px;
          height: 12px;
        }
      }
      &__round-box {
        width: 30px;
        height: 30px;
        background-color: #fabb05;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: $white;
        cursor: pointer;
      }
      &__name-box {
        display: flex;
        align-items: center;
        gap: 5px;
        &__text {
          font-size: 14px;
        }
        &__arrow {
          cursor: pointer;
          position: relative;

          img {
            height: 6px;
            width: 8px;
            object-fit: contain;
          }
        }
      }
    }
  }
}
