@import '../../../../styles/mixins';
@import '../../../../styles/variables';


.helpline-footer {
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  border-top: 0.5px solid #00152926;

  &--heading {
    font-size: 20px;
    margin-top: 19px;
    margin-bottom: 0;
    font-weight: bold;
    color: #000;
  }

  &--phonenumbers {
    border-bottom: 0.5px solid #00152926;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 26px;
    justify-content: space-between;
  }

  &--phonenumber {
    padding: 19px 0px 26px;
    margin-right: -29px;
    border-radius: 8px;
    min-width: 149px;
    direction: ltr;

    @media (max-width:819px){
      min-width: unset;
      padding: 19px 0 26px;
      margin: 0;

    }

    &-country {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      color: $dark-grey;
      margin-bottom: 11px;

      img {
        height: 10px;
        width: 14px;
        border-radius: 2px;
        margin-right: 5px;
        object-fit: contain;

        @include rtl-styles {
          margin-left: 5px;
        }
      }
    }

    &-link {
      color: black;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      text-align: left;
      text-decoration: none;

      i.icon {
        margin-right: 5px;
      }
    }

    &-description {
      font-size: 12px;
      margin-bottom: 0;
      font-weight: normal;
      color: $dark-grey;
      text-align: left;

      b {
        font-weight: 500;
      }
    }
  }

  &--email {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 12px;
      width: 15px;
      background-color: $white;
      margin-right: 5px;

      @include rtl-styles {
        margin-left: 5px;
      }
    }

    a {
      color: #000;
      text-decoration: none;
      font-size: 14px;
      font-family: Inter;
      font-weight: normal;
    }
  }
}
