@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.brand-detail-container {
  display: flex;
  padding: 30px 90px;
  gap: 159px;


  &__left-section {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__card-wrapper {
      width: 496px;
      height: 372px;
      padding: 21px 40px;
      background-color: $brand-card-wrapper-color;
      border-radius: 14px;

      &__info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      }
    }
  }
  &__right-section {
    display: flex;
    width: 100%;
    gap: 12px;

    &__right-contents {
      width: 100%;
      &__denomination-section {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 358px;
        gap: 40px;
        margin: 0 auto 23px auto;

        &__denom-value {
          display: flex;
          gap: 9px;
          margin-left: 16px;
          input {
            border: none;
            outline: none;
            width: 100%;
            font-size: 34px;
            font-weight: 600;
            color: $primary-button;
          }
          h4 {
            font-size: 34px;
            font-weight: 600;
            color: $primary-button;
          }
        }
      }
      &__amount-selection {
        display: flex;
        gap: 20px;
        margin-bottom: 30px;
        flex-wrap: wrap;
        &__single-item {
          width: 93px;
          height: 50px;
          background-color: $brand-card-wrapper-color;
          padding: 12px 0 13px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 12px;
          color: $primary-button;
          cursor: pointer;
        }
        &__other {
          width: 93px;
          height: 50px;
          background-color: $brand-card-wrapper-color;
          padding: 12px 0 13px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 12px;
          color: $primary-button;
          cursor: pointer;
        }
      }
      &__checkout-button {
        margin-bottom: 13px;
        button {
          border-radius: 12px;
          text-transform: capitalize;
          font-size: 16px;
          height: 50px;
        }
      }
      &__error-container {
        display: flex;
        align-items: center;
        gap: 3px;
      }
    }
  }
}

