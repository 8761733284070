@import "../../../../styles/mixins";
@import "../../../../styles/variables";

.profile-container {
  background-color: $white;
  height: calc(100vh - 110px);

  &__banner {
    background: $primary-button;
    height: 97px;
    width: 100%;
    background-image: url("../../../../../public/assets/images/corporate-banner-blue.png");
    background-size: cover;
    background-position: center;
    margin-bottom: 24px;
    h4 {
      text-align: center;
      padding-top: 35px;
      color: $white;
      font-size: 16px;
      font-weight: 600;
    }
  }
  section {
    padding: 0 25px;
  }

  &__box {
    max-width: 315px;
    width: 100%;
    background-color: $white;
    margin: 0 auto;
    border-radius: 12px;
    padding: 26px 20px;
    box-shadow: 0 1px 27px 0 rgba(22, 69, 135, 0.1);
    display: flex;
    flex-direction: column;
    gap: 27px;

    button {
      background-color: $primary-button;
      color: $white;
      width: 138px;
      height: 35px;
      text-transform: capitalize;
      border-radius: 8px;
      margin-bottom: 20px;
      &:hover {
        background-color: $primary-button;
        color: $white;
      }
    }
  }
}
