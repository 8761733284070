@import '../../../styles/mixins';
@import '../../../styles/variables';

.brand-detail-container {
  padding: 30px 80px;
  display: flex;
  flex-direction: column;
  &__back {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
    img {
      width: 6px;
      height: 12px;
      color: #3a1a5f;
    }
    h4 {
      font-size: 14px;
      font-weight: 500;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    &__left {
      display: flex;
      gap: 12px;
      align-items: center;
      img {
        width: 48px;
        height: 48px;
        border-radius: 6px;
      }
      h4 {
        font-size: 16px;
        font-weight: 500;
        color: $primary-button;
         max-width: 411px;
          overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
      }
    }
    &__right {
      color: #3a1a5f;
      font-size: 14px;
    }
  }
  &__logo-container {
    background-color: $brand-card-wrapper-color;
    padding: 21px 96px 40px;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
    justify-content: center;
    &__top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      &__validity {
        font-size: 14px;
        color: $primary-button;
        strong {
          font-weight: 600;
        }
      }
      &__instore {
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ffb779;
        font-size: 12px;
        font-weight: 500;
        color: #3a1a5f;
        padding: 4px 12px;
        border-radius: 6px;
      }
    }
    &__bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 416px;
        height: 266.7px;
        object-fit: contain;
        border-radius: 14px;
      }
    }
  }
  &__denomination-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 45px;
    margin: 0 auto 23px auto;
    width: 358px;

    &:focus-within {
      border-bottom: 2px solid $primary-button;
    }
    img {
      width: 28px;
      height: 28px;
      cursor: pointer;
    }
    &__denom-value {
      display: flex;
      gap: 12px;
      margin-left: 16px;
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type="number"] {
        -moz-appearance: textfield; /* Firefox */
      }
      input {
        border: none;
        outline: none;
        min-width: 100%;
        font-size: 34px;
        font-weight: 600;
        color: $primary-button;
        font-family: Poppins;
      }
      h4 {
        font-size: 34px;
        font-weight: 600;
        color: $primary-button;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &__amount-selection{
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
    flex-wrap: wrap;
    @media only screen and (max-width: 768px) and (min-width: 580px){
      justify-content: center;
    }
    &__single-item {
      width: 103.5px;
      height: 50px;
      background-color: $brand-card-wrapper-color;
      padding: 12px 0 13px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      color: $primary-button;
      cursor: pointer;
    }
    &__other {
      width: 93px;
      height: 50px;
      background-color: $brand-card-wrapper-color;
      padding: 12px 0 13px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      color: $primary-button;
      cursor: pointer;
      &:hover {
        border: 2px solid $primary-button;
      }
    }
  }
  &__checkout-button{
    margin-bottom: 13px;
    button {
      border-radius: 12px;
      text-transform: capitalize;
      font-size: 16px;
      height: 50px;
    }
  }
  &__error-container {
    display: flex;
    align-items: center;
    gap: 3px;
    img {
      width: 14px;
      height: 14px;
    }
    p {
      font-size: 14px;
      color: #f64744;
    }
  }
}

.active {
  border: 2px solid $primary-button;
   background-color: $white;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}