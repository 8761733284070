@import '././variables';

@mixin font-size($sizeValue: 1.6) {
    font-size: $sizeValue + px;
    font-size: calc($sizeValue / 10) + rem;
  }
  @mixin rtl-styles {
    [dir="rtl"] & {
      @content;
    }
  }
  @mixin rtl-rotate {
    [dir="rtl"] & {
      transform: scaleX(-1);
    }
  }
  
  @mixin content-ellipsis-overflow {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  @mixin for-mobile-phone-only {
    @media (max-width: $mob) {
      @content;
    }
    [mobile="yes"] & [tablet="false"] & {
      @content;
    }
  }
  @mixin for-tablet-device-only {
    @media (max-width: $md) {
      @content;
    }
    [mobile="yes"] & [tablet="yes"] & {
      @content;
    }
  }
  