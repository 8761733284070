.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  img {
    width: 100px;
    height: 100px;
  }
}
