@import '../../../../styles/mixins';
@import '../../../../styles/variables';
.brand-detail-container {
  background-color: #f2f5f8;
  min-height: calc(100vh - 200px);
  padding: 20px 16px;
  margin-bottom: 125px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    &__left {
      img {
        width: 6px;
        height: 12px;
        object-fit: contain;

      }
    }
   
  }
  &__logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12.5px;
    margin-bottom: 24px;
  }
  &__denomination-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin: 0 auto 23px auto;
    max-width: 284px;
    
    img {
      width: 28px;
      height: 28px;
      cursor: pointer;
    }
    &__denom-value {
      display: flex;
      gap: 10px;
      margin-left: 12px;
    }
  }
  &__amount-selection {
    display: flex;
    gap: 15px;
    margin-bottom: 30px;
    flex-wrap: wrap;

    &__single-item {
      width: 74px;
      height: 50px;
      background-color: $white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 6px;

    }
  }
  &__checkout-button {
    background-color: #f2f5f8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;;
    position: fixed;
    bottom: 60px;
    width: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
   
    
  }
}
