@import '../../../styles/mixins';
@import '../../../styles/variables';


.footer-container {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  box-shadow: 0 -10px 20px 0 rgba(176, 196, 255, 0.1);
  background-color: $white;
  @include for-tablet-device-only{
    height: 60px;
  }

  &__contents {
    display: flex;
    align-items: center;
    gap: 10px;

    &__text {
        font-size: 14px;
        font-family: Inter;
        font-weight: normal;
    }
   
      img {
        width: 25px;
        height: 17px;
        object-fit: contain;
      }
    
  }
}
