@import "../../styles/mixins";
@import "../../styles/variables";

.payment-success-container {
  &__inner-container {
    background-color: $primary-background;
    height: calc(100vh - 160px);

    @media (max-width: $md) {
      height: calc(100vh - 150px);
    }

    @media (max-width: $sm) {
      height: calc(100vh - 110px);
    }

    section {
      padding: 0 25px;
    }

    &__banner {
      background: $primary-button;
      height: 200px;
      width: 100%;
      background-image: url("../../../public/assets/images/corporate-banner-blue.png");
      background-size: cover;
      background-position: center;

      @media (max-width: $md) {
        height: 147px;
      }

      @media (max-width: $sm) {
        height: 156px;
      }
    }

    &__success-box {
      width: 1153px;
      max-width: 100%;
      height: 420px;
      background-color: $white;
      margin: -140px auto 0 auto;
      border-radius: 12px;
      padding: 30px 32px 47px 32px;

      @media (max-width: $md) {
        width: 608px;
        margin: -100px auto 0 auto;
      }

      @media (max-width: $sm) {
        width: 344px;
        margin: -120px auto 0 auto;
        padding: 17px 15px 40px 15px;
      }

      &__back-home {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        img {
          width: 6px;
          height: 12px;
          object-fit: contain;
          color: $primary-button;
          cursor: pointer;
        }
        a {
          font-size: 16px;
          color: $primary-button;
          @media (max-width: $sm) {
            font-size: 12px;
          }
        }
      }
      &__contents {
        text-align: center;
        img {
          width: 100px;
          height: 100px;
          margin-bottom: 20px;
          @media (max-width: $md) {
            height: 60px;
            width: 60px;
          }
          @media (max-width: $sm) {
            margin-bottom: 37px;
          }
        }
        h4 {
          font-size: 20px;
          font-weight: 600;
          color: #000;
          margin-bottom: 16px;
          @media (max-width: $sm) {
            font-size: 16px;
          }
        }
        p {
          font-size: 14px;
          color: $ptag-color;
          margin-bottom: 31px;
          @media (max-width: $md) {
            max-width: 267px;
            margin: 0 auto 19px auto;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.payment-success-page-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  @media (max-width: $md) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin-bottom: 52px;
  }
  @media (max-width: $sm) {
    margin-bottom: 60px;
  }
}
