@import "../../styles/mixins";
@import "../../styles/variables";

.payment-failure-container {
  &__inner-container {
    background-color: $primary-background;
    height: calc(100vh - 160px);

    @media (max-width: $md) {
      height: calc(100vh - 150px);
    }

    @media (max-width: $sm) {
      height: calc(100vh - 110px);
    }

    section {
      padding: 0 25px;
    }

    &__banner {
      background: $primary-button;
      height: 200px;
      width: 100%;
      background-image: url("../../../public/assets/images/corporate-banner-blue.png");
      background-size: cover;
      background-position: center;
      @media (max-width: $md) {
        height: 147px;
      }
      @media (max-width: $sm) {
        height: 156px;
      }
    }

    &__failure-box {
      width: 1153px;
      max-width: 100%;
      background-color: $white;
      margin: -140px auto 0 auto;
      border-radius: 12px;
      padding: 80px 0 50px 0;
      text-align: center;
      @media (max-width: $md) {
        margin: -100px auto 0 auto;
        width: 608px;
        padding-top: 40px;
      }
      @media (max-width: $sm) {
        width: 344px;
        padding: 40px 15px;
        margin: -120px auto 0 auto;
      }
      img {
        height: 100px;
        width: 100px;
        object-fit: contain;
        margin-bottom: 20px;
        @media (max-width: $md) {
          height: 60px;
          width: 60px;
        }
      }
      h4 {
        font-size: 20px;
        font-weight: 600;
        color: #000;
        margin-bottom: 14px;
        @media (max-width: $md) {
          font-size: 16px;
          font-weight: 600;
        }
      }
      p {
        color: $ptag-color;
        font-size: 16px;
        margin-bottom: 30px;
        @media (max-width: $md) {
          max-width: 247px;
          margin: 0 auto 19px auto;
          font-size: 12px;
        }
      }
      button {
        height: 50px;
        width: 180px;
        border: 1px solid $primary-button;
        color: $primary-button;
        font-size: 16px;
        text-transform: capitalize;
        &:hover {
          border: 1px solid $primary-button;
          background-color: $white;
        }
        @media (max-width: $md) {
          width: 314px;
          border-radius: 12px;
          font-weight: 600;
        }
        @media (max-width: $sm) {
          max-width: 314px;
          width: 100%;
        }
      }
    }
  }
}
