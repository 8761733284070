@import '../../../../styles/mixins';
@import '../../../../styles/variables';;

.brand-detail-container {
  padding: 30px 80px;
  display: flex;
  flex-direction: column;
  &__back {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
    img {
      width: 6px;
      height: 12px;
      color: #3a1a5f;
    }
    h4 {
      font-size: 14px;
      font-weight: 500;
      color: #3a1a5f;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    &__left {
      display: flex;
      gap: 12px;
      align-items: center;
    }
  }
  &__logo-container {
    background-color: $brand-card-wrapper-color;
    padding: 21px 96px 40px;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
    justify-content: center;
    &__top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      &__instore {
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ffb779;
        padding: 4px 12px;
        border-radius: 6px;
      }
    }
   
  }
  &__denomination-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 45px;
    margin: 0 auto 23px auto;

    img {
      width: 28px;
      height: 28px;
      cursor: pointer;
    }
  }
  &__amount-selection{
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
    flex-wrap: wrap;
  
    &__single-item {
      width: 103.5px;
      height: 50px;
      background-color: $brand-card-wrapper-color;
      padding: 12px 0 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
    }
  }
}
