@import "../../../../styles/mixins";
@import '../../../../styles/variables';

.resend-modal {
    width: 450px;
    height: 163px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.35);
    position: fixed;
    top: 11%;
    @media (max-width:$sm){
      width: 342px;
    }
    &__header {
      height: 60px;
      background-color: #eee;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 30px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      font-size: 22px;
      font-weight: 600;
      img {
        cursor: pointer;
      }
    }
    &__contents {
      padding: 30px;
      font-size: 16px;
    }
  }
  