@import "../../../../styles/mixins";
@import "../../../../styles/variables";

.filter-container {
  display: flex;
  gap: 20px;
  width: 940px;
  margin: 0 auto 40px;
  position: relative;

  @media (max-width: $md) {
    width: 608px;
  }

  @media (max-width: $sm) {
    width: 342px;
    margin: 0 auto 24px;
    gap: 14px;
  }

  @media (max-width: 375px) {
    width: unset;
  }

  &__search {
    box-shadow: 0 1px 27px 0 rgba(22, 69, 135, 0.1);
    border-radius: 12px;
    height: 50px;
    width: 77%;

    @media (max-width: $sm) {
      min-width: 239px;
    }

    @media (max-width: 375px) {
      min-width: unset;
    }
  }

  &__categories {
    width: 23%;
    height: 50px;
    background-color: $white;
    border-radius: 8px;
    border: solid 1px #f2f5f8;
    padding: 16px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    @media (max-width: $sm) {
      min-width: 89px;
      padding: 11px 8px;
    }

    @media (max-width: 375px) {
      min-width: unset;
      padding: 8px 3px;
    }

    p {
      font-size: 12px;
      color: #3c3f43;
      @media (max-width: $sm) {
        font-size: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    img {
      width: 10px;
      height: 6px;
    }
  }
}
