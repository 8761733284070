@import "../../styles/mixins";
@import "../../styles/variables";

.corporate-selection-box {
  background: $primary-background;
  text-align: center;
  display: flex;
  min-height: calc(100vh - 180px);
  align-items: center;
  flex-direction: column;
  position: relative;
  @media (max-width: $md) {
    min-height: calc(100vh - 150px);
  }
  @media (max-width: $sm) {
    min-height: calc(100vh - 110px);
  }

  &__banner {
    background: $primary-button;
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      height: 200px;
      width: 100%;
      object-fit: cover;
      @media (max-width: $md) {
        height: 147px;
      }
    }
    @media (max-width: $md) {
      height: 147px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h3 {
      color: $white;
      font-size: 24px;
      font-weight: 600;
      margin: 45px 0;
      position: absolute;
    }
  }
  &__container {
    width: 1153px;
    max-height: 1004px;
    overflow: auto;
    max-width: 1154px;
    background-color: $white;
    margin: -100px auto 20px;
    padding: 30px;
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 26px;
    justify-content: center;

    @media (max-width: 1250px) {
      max-width: 784px;
    }

    @media (max-width: $md) {
      max-width: 606px;
      margin-top: 30px;
      padding: unset;
      gap: 20px;
      width: 100%;
      background-color: #f0f2f5;
    }

    @media (max-width: $sm) {
      max-width: 343px;
      gap: 15px;
    }

    &__card {
      height: 294px;
      width: 347px;
      background-color: $white;
      box-shadow: 0 0 60px 0 rgba(60, 47, 143, 0.2);
      border-radius: 10px;
      padding: 20px 20px 17px 22px;
      flex: 0 0 calc(33.33% - 26px);
      cursor: pointer;
      @media (max-width: 1250px) {
        min-width: 347px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      transition: border-color 0.5s, transform 0.5s;
      &:hover {
    border: 1px solid $primary-button;
    transform: scale(0.98); 
  }
      @media (max-width: $md) {
        min-width: 293px;
        height: 248px;
        padding: 16px 17px 14px 17px;
        box-shadow: unset;
      }
      @media (max-width: $sm) {
        min-width: 163px;
        padding: 10px 11.5px;
        height: 148px;
      }

      &__balance {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        @media (max-width: $md) {
          margin-bottom: 13px;
        }
        @media (max-width: $sm) {
          margin-bottom: 10px;
        }

        &__text {
          font-size: 14px;
          color: rgba(65, 65, 65, 0.45);
          @media (max-width: $sm) {
            font-size: 10px;
          }
        }

        &__amount {
          font-size: 16px;
          font-weight: 600;
          color: $primary-button;
          @media (max-width: $sm) {
            font-size: 12px;
          }
        }
      }

      &__logo {
        height: 182px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f2f5f8;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        margin-bottom: 14px;
        img {
          max-height: 108px;
          max-width: 207px;

          @media (max-width: $md) {
            max-height: 108px;
            max-width: 175px;
          }

          @media (max-width: $sm) {
            max-height: 57px;
            max-width: 98px;
          }
        }

        @media (max-width: $md) {
          width: 100%;
          height: 153px;
          margin-bottom: 12px;
        }

        @media (max-width: $sm) {
          width: unset;
          height: 86px;
          margin-bottom: 5px;
        }
      }

      &__name {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        max-width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
  
        @media (max-width: $sm) {
          font-size: 10px;
        }
      }
    }
  }
}

.corporate-selected {
  opacity: 0.5;
  cursor: not-allowed;
}
