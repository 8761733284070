@import "../../styles/mixins";
@import "../../styles/variables";


.activation-form {
  &-fields {
    background: #f7f6f9;
    border-radius: 6px;

    &:nth-child(n + 1) {
      margin-bottom: 30px;

      @include for-mobile-phone-only{
        margin-bottom: 20px;
      }
    }
  }
}
