@import "../../../../styles/mixins";
@import "../../../../styles/variables";

.checkout-modal {
  width: 450px;
  height: 229px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 11%;
  @media (max-width: $sm) {
    width: 345px;
    height: 255px;
  }
  &__header {
    height: 60px;
    background-color: #eeeeee;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    color: #000000;
    font-size: 22px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-left: 30px;
    @include rtl-styles {
      padding-right: 30px;
    }
  }
  &__contents {
    padding: 10.5px 30px;
    font-size: 16px;
    strong {
      font-weight: 600;
    }
  }
  &__footer {
    display: flex;
    gap: 26px;
    padding: 18px 42px;
    width: 100%;
    @media (max-width: $sm) {
      gap: 19px;
    }
    button {
      width: 170px;
      height: 50px;
      border-radius: 12px;
      font-size: 16px;
      font-weight: 600;
      border: 2px solid $primary-button;
      @media (max-width: $sm) {
        width: 132px;
      }
    }
  }
}
