.progressiveImg {
  max-width: 100%;
  height: 832px;
  object-fit: cover;

  &__loading {
    filter: blur(10px);
    clip-path: inset(0);
  }

  &__loaded {
    filter: blur(0);
    transition: filter 0.5s linear;
  }
}
