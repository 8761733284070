@import '../../../styles/mixins';
@import '../../../styles/variables';

.brand-detail-container {
  background-color: #f2f5f8;
  min-height: calc(100vh - 200px);
  padding: 20px 16px;
  margin-bottom: 125px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
 

    &__left {
      img {
        width: 6px;
        height: 12px;
        object-fit: contain;
      }
    }
    &__right {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      color: $primary-button;
      max-width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-left: 10px;
    }
  }
  &__logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12.5px;
    margin-bottom: 24px;
    img {
      width: 284px;
      height: 182.7px;
      object-fit: contain;
      border-radius: 14px;
    }
    &__texts {
      display: flex;
      gap: 2px;
      span {
        font-size: 12px;
        color: $primary-button;
      }
    }
  }
  &__denomination-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin: 0 auto 23px auto;
    max-width: 284px;

    &:focus-within {
      border-bottom: 2px solid $primary-button;
    }
    img {
      width: 28px;
      height: 28px;
      cursor: pointer;
    }
    &__denom-value {
      display: flex;
      gap: 10px;
      margin-left: 12px;
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type="number"] {
        -moz-appearance: textfield; /* Firefox */
      }
      input {
        border: none;
        outline: none;
        min-width: 100%;
        font-size: 24px;
        font-weight: 600;
        color: $primary-button;
        font-family: Poppins;
        background-color: $brand-card-wrapper-color;
      }
      h4 {
        font-size: 24px;
        font-weight: 600;
        color: $primary-button;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &__amount-selection {
    display: flex;
    gap: 15px;
    margin-bottom: 30px;
    flex-wrap: wrap;
    @media(max-width:375px){
      justify-content: center;
    }
    &__single-item {
      width: 74px;
      height: 50px;
      background-color: $white;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      color: $primary-button;
      cursor: pointer;
    }
    &__other {
      width: 74px;
      height: 50px;
      background-color: $white;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      color: $primary-button;
      cursor: pointer;
      &:hover {
        border: 2px solid $primary-button;
      }
    }
  }
  &__error-container {
    display: flex;
    align-items: center;
    gap: 3px;
    img {
      width: 14px;
      height: 14px;
    }
    p {
      font-size: 12px;
      color: #f64744;
    }
  }
  &__checkout-button {
    background-color: #f2f5f8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 16px;
    position: fixed;
    bottom: 60px;
    width: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
   
    button {
      border-radius: 12px;
      text-transform: capitalize;
      font-size: 16px;
      height: 50px;
      max-width: 342px;
     
      
    }
  }
}

.active {
    border: 2px solid $primary-button;
     background-color: $white;
  }
  
  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }