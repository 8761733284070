@import "../../../../styles/mixins";
@import "../../../../styles/variables";

.gift-container {
  background-color: white;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(4, 297px);
  width: 1260px;
  margin-bottom: 20px;

  &__box {
    display: flex;
    justify-content: center;
    max-width: 1260px;
    &__card {
      width: 100%;
      height: 305px;
      border-radius: 12px;
      box-shadow: 0 1px 27px 0 rgba(22, 69, 135, 0.1);
      cursor: pointer;
      padding: 17.5px 16px;
      position: relative;
      @media (max-width: $sm) {
        box-shadow: unset;
        padding: 0;
        height: unset;
      }

      &__logo-box {
        min-width: 100%;
        max-height: 168px;
        border: 1px solid #e6e6e6;
        border-radius: 12px;
        margin-bottom: 16px;
      }

      &__brand-name {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &__brand-type {
        font-size: 14px;
        color: $ptag-color;
        margin-bottom: 16px;
        text-align: left;
      }
    }
  }
}
