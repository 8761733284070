@import "../../styles/mixins";
@import "../../styles/variables";

.redemption-history-container {
  background-color: $white;
  height: calc(100vh - 180px);
  @media (max-width: $md) {
    height: calc(100vh - 150px);
  }
  @media (max-width: $sm) {
    height: calc(100vh - 110px);
  }

  &__banner {
    background: $primary-button;
    height: 200px;
    width: 100%;
    background-image: url("../../../public/assets/images/corporate-banner.png");
    background-image: url("../../../public/assets/images/corporate-banner-blue.png");
    background-size: cover;
    background-position: center;
    @media (max-width: $md) {
      height: 147px;
      display: flex;
      align-items: center;
      justify-content: center;
      h4 {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
      }
    }
    @media (max-width: $sm) {
      height: 97px;
      h4 {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  section {
    padding: 0 25px 100px 25px;
  }

  &__box {
    width: 1260px;
    max-width: 100%;
    background-color: $white;
    margin: -171px auto 0 auto;
    border-radius: 12px;
    padding: 25px 24px;
    box-shadow: 0 1px 27px 0 rgba(22, 69, 135, 0.1);

    @media (max-width: $md) {
      margin: 33px auto 0 auto;
      max-width: 608px;
      width: 100%;
      padding: 27px 21px;
    }

    @media (max-width: $sm) {
      max-width: 315px;
      width: 100%;
      padding: 26px 20px;
    }

    h4 {
      font-size: 24px;
      font-weight: 600;
      color: #000;
      margin-bottom: 39px;

      @media (max-width: $md) {
        display: none;
      }
    }

    &__date-pickers {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 30px;
      @media (max-width: $sm) {
        flex-direction: column;
        gap: 12px;
      }
    }
  }
}
