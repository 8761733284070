@import "../../../../styles/mixins";
@import "../../../../styles/variables";

.hamburger-drawer-container {
  padding: 60px 23px 0 29px;
  &__payments {
    display: flex;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid rgba(0, 21, 41, 0.08);
    padding-bottom: 20px;

    &__logo {
      background-color: $primary-button;
      width: 40px;
      height: 40px;
      padding: 8px;
      border-radius: 8px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    &__amounts {
      line-height: 1.1;
      &__text {
        font-size: 14px;
        color: rgba(65, 65, 65, 0.45);
      }
      &__amount {
        color: $primary-button;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
  &__user-details {
    margin: 19px 0;
    &__user {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      h4 {
        font-size: 16px;
        font-weight: 500;
        color: #000;
      }
      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
    }
    &__basic-details {
      display: flex;
      align-items: center;
      padding-bottom: 7px;
      &__text {
        height: 50px;
        width: 50px;
        background-color: #fabb05;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        font-size: 18px;
        position: relative;
        margin-right: 10px;
        padding: 0 16px;
        @include rtl-styles{
          margin-right: 0;
          margin-left: 10px;
        }      }
      &__green-tick {
        position: absolute;
        top: 220px;
        left: 60px;
        @include rtl-styles{
          left: 0;
          right: 55px;
          top: 213px;
        }
        img {
          height: 16px !important;
          width: 16px !important;
          object-fit: contain;
        }
      }
      &__name-email {
        h4 {
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
        }
        h3 {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    &__switch-button {
      border-bottom: 1px solid rgba(0, 21, 41, 0.08);
      padding-bottom: 20px;
      text-align: center;
      button {
        width: 125px;
        height: 25px;
        font-size: 12px;
        border-radius: 4px;
        border: 1px solid $primary-button;
        line-height: unset;
        padding: 14px 6px;
        text-transform: capitalize;
      }
    }
  }
  &__history {
    display: flex;
    flex-direction: column;
    gap: 9px;
    border-bottom: 1px solid rgba(0, 21, 41, 0.08);
    padding-bottom: 20px;
    margin-bottom: 20px;
    &__language-switch{
      width: 239px;
      height: 50px;
      border: 1px solid #e4e4e4;
      border-radius: 50px;
      background-color: $white;
      padding: 9px 22px;
      font-size: 14px;
      color: #000;
      line-height: 2;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__text{
        font-size: 14px;
        font-family: Cairo;
        font-weight: 600;
        color: $arabic-switch-color;
      }
    }
    &__credit {
      width: 239px;
      height: 50px;
      border: 1px solid #e4e4e4;
      border-radius: 50px;
      background-color: $white;
      padding: 9px 22px;
      font-size: 14px;
      color: #000;
      line-height: 2;
    }
    &__redemption {
      width: 239px;
      height: 50px;
      border: 1px solid #e4e4e4;
      border-radius: 50px;
      background-color: $white;
      padding: 9px 22px;
      font-size: 14px;
      color: #000;
      line-height: 2;
    }
  }
  &__sign-out-btn {
    button {
      height: 35px;
      text-transform: capitalize;
      font-size: 16px;
      color: $primary-button;
      border: solid 1px #c4bfe1;
    }
  }
}
