@import "../../../../styles/mixins";
@import "../../../../styles/variables";

.header-container {
  height: 100px;
  width: 100%;
  padding: 25px 90px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  position: relative;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 1260px;
    width: 100%;

    &__left {
      display: flex;
      align-items: center;

      &__logo {
        display: flex;
        margin-right: 21px;
        img {
          width: 170px;
          height: 46px;
          object-fit: contain;
        }
      }
      &__stores {
        display: flex;
        gap: 5px;
        align-items: center;
        cursor: pointer;
        position: relative;
        margin-right: 12px;
        @include rtl-styles{
          margin-left: 12px;
        }

        &__flag {
          width: 20px;
          height: 16px;
          object-fit: contain;
          border-radius: 4px;
        }
        p {
          font-size: 16px;
          font-weight: 600;
          color: $store-button-text;
          max-width: 300px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          
          @media (max-width: $lg){
              max-width: 200px;
            }
           @media (max-width: $lg-md){
              max-width: 70px;
            }
        
        }
        &__down-arrow {
          width: 12px;
          height: 6px;
        }
      }
      &__arabic-switch {
        font-size: 14px;
        font-weight: 600;
        font-family: Cairo;
        color: $arabic-switch-color;
        cursor: pointer;
      }
    }

    &__right {
      display: flex;

      &__history {
        display: flex;
        align-items: center;
        gap: 12px;

        a {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
          color: #000000;
          text-decoration: none;
        }

        &::after {
          content: "";
          height: 40px;
          background-color: #cad1d8;
          width: 1px;
          margin: 0 30px 0 15px;
        }
      }
      &__account-balance {
        display: flex;
        align-items: center;
        margin-right: 31px;
        &__wallet-icon {
          height: 40px;
          width: 40px;
          background-color: $primary-button;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          img {
            width: 24px;
            height: 24px;
          }
        }
        &__texts {
          margin-right: 18px;
          @include rtl-styles {
            margin-left: 18px;
          }
          p {
            font-size: 14px;
            color: rgba(65, 65, 65, 0.45);
          }
          h4 {
            font-size: 20px;
            font-weight: 600;
            line-height: 1;
            color: $primary-button;
          }
        }
        &::after {
          content: "";
          height: 40px;
          background-color: #cad1d8;
          width: 1px;
          margin-left: 19px;
          @include rtl-styles {
            margin-right: 19px;
          }
        }
      }
      &__my-account {
        position: relative;
        cursor: pointer;
        &__round-box {
          height: 31px;
          width: 31px;
          background-color: #fabb05;
          border-radius: 50%;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          color: $white;
        }
        &__name-box {
          cursor: pointer;
          display: flex;
          align-items: flex-end;
          gap: 5px;
          &__text {
            font-size: 14px;
            color: #3c2f8f;
          }
          &__arrow {
            
            img {
              height: 5.3px;
              width: 8px;
              object-fit: contain;
              color: #3c2f8f;
            }
          }
        }
      }
    }
  }
}
