@import "../../../../styles/mixins";
@import "../../../../styles/variables";
.left-container {
  width: 100%;
  padding: 0 50px 0 0;

  &__heading {
    width: 140px;
    height: 28px;
    margin: 0 0 42px 0;
    font-size: 24px;
    font-weight: 500;
    text-align: left;
    color: $primary-button;
  }
  &__logo-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    &__logo-name {
      display: flex;
      gap: 12px;

      &__logo-brand {
        padding: 14px 0;
      }
    }
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 60px 0 30px 0;

    &__edit-remove-container {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
  }

  &__line {
    width: 100%;
    height: 1px;
    background-color: $light-grey;
  }
}
