$grey: #dad8e2;
$primary: #c028b9;
$primary-button: #332879;
$dark-grey: #212529; 
$ptag-color: #808080;
$white: #fff;
$primary-background: #f0f2f5;
$brand-card-wrapper-color: #f2f5f8;
$light-black:#545454;
$light-grey: #d9dfe4;
$pin-color: #727374;
$store-button-text: #3a1a5f;
$orange:#ffb779;
$table-header-color: #fafafa;
$privacy-text:#687dd8;
$black:#333;
$real-black: #000;
$arabic-switch-color:#3c2f8f;

// breakpoints
$xxxl:1430px;
$xxl: 1400px;
$xxl-xl:1336px;
$xl: 1320px;
$lg: 1290px;
$lg-md:1190px;
$md: 1063px;
$sm: 767px;
$mob: 480px;
 