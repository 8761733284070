@import '../../../../styles/mixins';
@import '../../../../styles/variables';
.error-container{
    
    height: 100vh;
    background-color: $primary-background;
    display: flex;
    justify-content: center;
    align-items: center;

  &__inner{
    position: relative;

    &__img{
       width: 717.2px;
       height: 377.9px;
  @media (max-width: 1024px) {
   width: 100%;
       height: 100%;
  }
    }

    &__text-container{
        top: 270px;
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
         @media (max-width: 1024px) {
             top: 73%;
           }

        &__error-number{
            font-size: 80px;
            font-weight: bold;
            height: 97px;
            @media (max-width: 1024px) {
             font-size: 60px;
             height: 70px;
           }
           @media (max-width: 768px) {
             font-size: 40px;
             height: 50px;
           }
        }
        &__error-request{
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 4.8px;
            @media (max-width: 768px) {
            margin-bottom: 2.8px;
           }
        }
        &__error-details{
            font-size: 13px;
            font-weight: 500;
            color: $black;
            width: 322px;
            text-align: center;
            line-height: 0.8rem;
             @media (max-width: 768px) {
             width: 200px;
           }
        }
    }
  }
  &__footer{
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
    padding: 26px 0;
    background-color:$white;
    font-size: 14px;

    &__privacy-text{
          color: $privacy-text;
    }
  }
}

// width={717.2} height={377.9}