@import '../../../../styles/mixins';
@import '../../../../styles/variables';


.header-tab-container {
  height: 90px;
  padding: 33px 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: $md) {
    width: 608px;
    margin: 0 auto;
    padding: 0;
    position: relative;
  }

  @media (max-width: $sm) {
    height: 50px;
    width: 100%;
    position: relative;
    max-width: 342px;
  }
  @media(max-width:375px){
    padding: 0 16px;
  }

  &__stores {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    width: 150px;

    &__flag {
      width: 19px;
      height: 16px;
      border-radius: 4px;
    }

    p {
      font-size: 14px;
      font-weight: 600;
      color: #3a1a5f;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @media(max-width:$sm){
       max-width: 90px;
     }
    }

    &__down-arrow {
      width: 14px;
      height: 7px;
    }
  }
  &__logo {
    img {
      height: 50px;
      width: 60px;
      object-fit: contain;
      @media (max-width: $sm) {
        height: 20px;
        width: 30px;
      }
    }
  }
  &__hamburger {
    width: 150px;
    text-align: right;
    @include rtl-styles {
      text-align: left;
    }
    img {
      height: 12px;
      width: 16px;
      object-fit: contain;
    }
  }
}
