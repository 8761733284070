@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.brand-detail-container {
  display: flex;
  padding: 30px 90px;
  gap: 159px;

  @media (max-width: $xxl) {
    gap: 130px;
    padding: 30px 75px;
  }
  @media (max-width:1280px){
    gap: 60px;
    padding: 30px 50px;
  }

  &__left-section {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__card-wrapper {
      width: 496px;
      height: 372px;
      padding: 21px 40px;
      background-color: $brand-card-wrapper-color;
      border-radius: 14px;

      &__info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        &__validity {
          font-size: 14px;
          font-family: Roboto;
          line-height: 1.71;
          color: $primary-button;
          strong {
            font-weight: 500;
          }
        }
        &__in-store {
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #ffb779;
          border-radius: 6px;
          font-size: 12px;
          font-weight: 500;
          background-color: $white;
          color: #3a1a5f;
          padding: 12px;
        }
      }
      &__logo {
        img {
          height: 266px;
          width: 416px;
          object-fit: contain;
          border-radius: 14px;
        }
      }
    }
  }
  &__right-section {
    display: flex;
    width: 100%;
    gap: 12px;

    &__left-logo {
      img {
        height: 48px;
        width: 48px;
        border-radius: 6px;
      }
    }
    &__right-contents {
      width: 100%;
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 31px;
        &__left {
          h4 {
            font-size: 24px;
            color: $primary-button;
            font-weight: 500;
            max-width: 420px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            @media (max-width: $xxxl){
              max-width: 390px;
            }
            @media (max-width: $xxl){
              max-width: 380px;
            }
             @media (max-width: $xxl-xl){
              max-width: 350px;
            }
             @media (max-width: $xl){
              max-width: 330px;
            }
             @media (max-width: $lg){
              max-width: 350px;
            }
            @media (max-width: $lg-md){
              max-width: 230px;
            }
           
          }
        }
        &__right {
          color: #3a1a5f;
          font-size: 14px;
          
        }
      }
      &__denomination-section {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 358px;
        gap: 40px;
        margin: 0 auto 23px auto;

        &:focus-within {
          border-bottom: 2px solid $primary-button;
        }
        img {
          width: 28px;
          height: 28px;
          cursor: pointer;
        }
        &__denom-value {
          display: flex;
          gap: 9px;
          align-items: center;
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type="number"] {
            -moz-appearance: textfield; /* Firefox */
          }
          input {
            border: none;
            outline: none;
            min-width: 100%;
            font-size: 34px;
            font-weight: 600;
            color: $primary-button;
            font-family: Poppins;
          }
          span {
            font-size: 34px;
            font-weight: 600;
            color: $primary-button;
            font-family: Poppins;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      &__amount-selection {
        display: flex;
        gap: 20px;
        margin-bottom: 30px;
        flex-wrap: wrap;
        &__single-item {
          width: 93px;
          height: 50px;
          background-color: $brand-card-wrapper-color;
          padding: 12px 0 13px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 12px;
          color: $primary-button;
          cursor: pointer;
        }
        &__other {
          width: 93px;
          height: 50px;
          background-color: $brand-card-wrapper-color;
          padding: 12px 0 13px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 12px;
          color: $primary-button;
          cursor: pointer;
          &:hover {
            border: 2px solid $primary-button;
          }
        }
      }
      &__checkout-button {
        margin-bottom: 13px;
        button {
          border-radius: 12px;
          text-transform: capitalize;
          font-size: 16px;
          height: 50px;
        }
      }
      &__error-container {
        display: flex;
        align-items: center;
        gap: 3px;
        img {
          width: 14px;
          height: 14px;
        }
        p {
          font-size: 14px;
          color: #f64744;
        }
      }
    }
  }
}

.active {
  border: 2px solid $primary-button;
  background-color: $white;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
