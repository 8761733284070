@import '../../../../styles/mixins';
@import '../../../../styles/variables';


.footer-container {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  box-shadow: 0 -10px 20px 0 rgba(176, 196, 255, 0.1);
  background-color: $white;
  position: fixed;
  bottom: 0;

  &-contents {
    display: flex;
    padding: 21px 0;
    gap: 10px;

    &-text {
      font-size: 14px;
      font-family: Inter;
      font-weight: normal;
    }
    &-logo {
      img {
        width: 25px;
        height: 17px;
        object-fit: contain;
      }
    }
  }
}
