@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.error-container {
  text-align: center;
  padding: 140px 0;
  height: 100vh;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__imoji-container {
      height: 206px;
      width: 206px;
      border-radius: 50%;
      background-color: #f4f4f4;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;

      img {
        height: 80px;
        width: 80px;
        object-fit: contain;
      }
    }

    &__error-contents {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 60px;

      p{
        font-size: 14px;
        font-family: Inter;
        color: #8e8e8e;
        @media(max-width:$sm){
          max-width: 250px;
        }
      }

      h4{
        font-size: 24px;
        font-weight: 600;
        font-family: Inter;
        color: #1f1f1f;
      }
    }
    &__back-button{
        button{
            height: 50px;
            width: 314px;
            border-radius: 12px;
            text-transform: capitalize;
            font-size: 14px;
            font-family: Inter;
            font-weight: 600;
            padding: 16px 0;
        }
    }
  }
}
