@import "../../../styles/mixins";
@import "../../../styles/variables";

.profile-container {
  background-color: $white;
  height: calc(100vh - 180px);

  &__banner {
    background: $primary-button;
    height: 200px;
    width: 100%;
    background-image: url("../../../../public/assets/images/corporate-banner-blue.png");
    background-size: cover;
    background-position: center;

    @media (max-width: $md) {
      height: 147px;
    }

    h4 {
      text-align: center;
      padding-top: 35px;
      color: $white;
      font-size: 18px;
      font-weight: 500;
      @media (max-width: $md) {
        font-size: 24px;
        font-weight: 600;
        padding-top: 57px;
      }
    }
  }

  section {
    padding: 0 25px;
    @media (max-width: $md) {
      padding: 0 80px;
    }
  }

  &__box {
    width: 1260px;
    max-width: 100%;
    height: 275px;
    background-color: $white;
    margin: -100px auto 0 auto;
    border-radius: 12px;
    padding: 46px 42px 38px;
    box-shadow: 0 1px 27px 0 rgba(22, 69, 135, 0.1);
    display: flex;
    flex-direction: column;
    gap: 27px;

    @media (max-width: $md) {
      margin: 33px auto 0 auto;
      width: 608px;
      padding: 46px 11px 42px;
      border-radius: 16px;
    }

    &__bottom-fields {
      display: flex;
      gap: 22px;
      @media (max-width: $md) {
        gap: 18px;
      }
    }

    button {
      background-color: $primary-button;
      color: $white;
      width: 138px;
      height: 35px;
      text-transform: capitalize;
      border-radius: 8px;
      &:hover {
        background-color: $primary-button;
        color: $white;
      }
    }
  }
}


