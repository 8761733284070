@import "../../../../styles/mixins";

.authLayout {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @include for-mobile-phone-only {
    min-height: 100vh;
    height: unset;
    display: block;
  }

  &__container {
    height: 832px;
    width: 100%;
    display: grid;
    grid-template-columns: 41.5% 58.5%;

    @include for-tablet-device-only {
      grid-template-columns: 100%;
      width: 100%;
    }

    @include for-mobile-phone-only {
      grid-template-columns: 100%;
    }

    &__left {
      @include for-tablet-device-only {
        display: none;
      }

      @include for-mobile-phone-only {
        display: none;
      }

      img {
        @include for-mobile-phone-only {
          display: none;
        }
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: -75px;
      position: relative;

      @include for-tablet-device-only {
        margin-left: unset;
      }
      @include for-mobile-phone-only {
        display: block;
        margin-left: unset;
      }
      &__outlet {
        @include for-mobile-phone-only {
          padding-top: 65px;
          position: relative;
          z-index: 1;
          background-color: #f7f6f9;
          min-height: calc(100vh - 60px);
          img {
            height: 150px;
            width: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            z-index: -1;
          }
        }
        &__container {
          @include for-mobile-phone-only {
            background-color: $white;
            padding: 15px;
            margin: 16px;
            border-radius: 12px;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
          }
        }
      }

      &__sub {
        max-width: 511px;
        position: relative;
        margin: auto;
        width: 100%;

        @include for-mobile-phone-only {
          max-width: unset;
          padding: 0;
        }
      }

      .auth-logo-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 43px;
        align-items: baseline;

        @include for-mobile-phone-only {
          margin-bottom: 0;
          padding: 17px;
          align-items: center;
        }
        &__logo-container {
          width: 83px;
          height: 55px;
          @include for-mobile-phone-only {
            width: 40px;
            height: 26px;
          }
          img {
            display: block;
            width: 100%;
          }
        }

        &__mobile-helpline-container {
          display: flex;
          align-items: center;
          gap: 20px;
          img {
            height: 20px;
            width: 20px;
          }
        }

        &__text {
          color: $primary;
          cursor: pointer;
          font-size: 16px;
          font-weight: 600;
          font-family: Cairo;
        }
      }
    }
  }
}
