@import '../../../../styles/mixins';
@import '../../../../styles/variables';


.filters-container {
  display: flex;
  align-items: center;
  gap: 25px;
  flex-wrap: wrap;
  margin-bottom: 40px;
  @media (max-width: $lg) {
    width: 939px;
    margin: 0 auto 40px;
  }

  &__category {
    flex: 0.83;
    position: relative;
    &__select-container {
      position: relative;

      label {
        position: absolute;
        top: -10px;
        left: 12px;
        color: #000;
        font-size: 13px;
        line-height: 1.54;
        background-color: white;
        padding: 0 5px;
        transition: all 0.3s;
        z-index: 999;
        @include rtl-styles{
          left: initial;
          right: 12px;
        }
      }
      input {
        width: 100%;
        padding: 19.5px 16px;
        border: 1px solid #808080;
        border-radius: 8px;
        outline: none;
        font-size: 16px;
        font-weight: 600;
        opacity: 0.8;
        font-family: Roboto;
        cursor: pointer;
      }
      img {
        top: 22px;
        position: absolute;
        height: 16px;
        width: 16px;
        right: 10px;
        @include rtl-styles{
          left: 10px;
          right: unset;
        }
      }
      input:focus + label,
      input:not(:placeholder-shown) + label {
        transform: translateY(-100%);
        font-size: 0.8rem;
        background-color: #fff;
        padding: 0 4px;
      }
    }
  }
  &__search {
    flex: 1.71;
  }
}
