@import '../../../styles/mixins';
@import '../../../styles/variables';

.view-giftcard-container {
  display: flex;
  background-color: $brand-card-wrapper-color;   
  padding-bottom: 125px;

  &__left-section {
    display: flex;
    flex-direction: column;
     justify-content: center;

    &__card-wrapper {
      width: 607px;
      padding: 20px 45px 24px 46px;
     display: flex;
     flex-direction: column;
     justify-content: flex-start;
     align-items: center;
     @media screen and (max-width: 768px) {
       width: 100vw;
     } 

     &__info {  
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: 21.5px; 
        gap: 14px;
        
        &__brand-name{
          font-size: 20px;
          font-weight: 500;
          color: $primary-button;
          max-width: 300px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &__validity {
          font-size: 14px;
          line-height: 1.71;
          color: $primary-button;
          strong {
            font-weight: 500;
          }
        }
        
      }
      &__logo {
        img {
          height: 182.7px;
          width: 284px;
          object-fit: contain;
          border-radius: 14px;
          
        }
      }
      &__details{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        &__price{  
        margin-bottom: 7px;
        font-size: 24px;
        font-weight: 600;
         color: $primary-button;
        }

        &__barcode{    
        mix-blend-mode: darken;
         position: relative;
        &__digit{
            font-size: small;
          bottom: -11px;
          width: 100%;
          text-align: center; 
          position: absolute;
        }

        }
        &__pin{
          margin-top: 10.6px;
          font-size: 14px;
          &__text{

            color: $pin-color;
          }

          &__value{
            color: $primary-button;
          }
        }
      }
    }
    &__accordions{
         display: flex;
         justify-content: center;
         align-items: flex-start;
         width: 100%;
         padding: 15px 16px 17px  16px ;
         background-color: $brand-card-wrapper-color;
         margin: 0 auto;
         max-width: 375px;   
    }
    &__arrow-back{
      position: absolute;
      top: 75px;
      left: 17px;
      cursor: pointer;
    }
  }
  
}