@import "variables";
@import "mixins";

// css variables
:root {
  --grey: #dad8e2;
  --primary: #c028b9;
  --primary-button: #332879;
  --dark-grey: #212529;
  --ptag-color: #808080;
  --white: #fff;
  --primary-background: #f0f2f5;
  --brand-card-wrapper-color: #f2f5f8;
  --light-black: #545454;
  --light-grey: #d9dfe4;
  --pin-color: #727374;
  --store-button-text: #3a1a5f;
  --orange: #ffb779;
  --table-header-color: #fafafa;
  --privacy-text: #687dd8;
  --black: #333;
  --real-black: #000;
  --arabic-switch-color: #3c2f8f;
}

/* Chrome, Safari, Edge, Opera */
/* stylelint-disable */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* stylelint-enable */

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Roboto";
  background-color: $white;
  color: $real-black;

  @include rtl-styles {
    font-family: Cairo, sans-serif !important;
  }
}

input,
fieldset,
button,
select,
textarea {
  font-family: "Roboto", sans-serif !important;

  @include rtl-styles {
    font-family: Cairo, sans-serif !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul {
  padding: 0;
  margin: 0;
  font-weight: normal;
  max-width: 100%;
  word-wrap: break-word;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.wrapper {
  max-width: 1600px;
  margin: 0 auto;
}

.corporate-selection-wrapper {
  max-width: 1600px;
  margin: 0 auto;
}

.dashboard-wrapper {
  max-width: 1310px;
  margin: 0 auto;
  padding: 45px 25px;

  @media (max-width: $sm) {
    padding: 16px;
  }
}

.brand-detail-wrapper {
  max-width: 1440px;
  margin: 0 auto 60px auto;
}

.brand-detail-tab-wrapper {
  max-width: 768px;
  margin: 0 auto 60px auto;
}

.brand-detail-mobile-wrapper {
  max-width: 342px;
  margin: 0 auto;
}

.MuiFormHelperText-root.Mui-error {
  margin-left: 0;
  line-height: 1.3;
}

.activation-form .MuiTextField-root {
  width: 100%;
  background-color: white;
}

.activation-form .MuiOutlinedInput-input {
  padding: 13.5px 20px;
  font-family: Roboto;
  font-size: 16px;

  @include for-mobile-phone-only {
    padding: 13.5px;
  }
}

.activation-form .MuiOutlinedInput-notchedOutline {
  border: solid 0.5px $grey;
}

.activation-form
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline
  .Mui-focused {
  border: solid 0.5px $grey;
}

.activation-form .MuiOutlinedInput-root {
  border-radius: 6px !important;
  background-color: #f7f6f9;
}

.activation-form .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: $grey;
}
:focus-visible {
  outline: none;
}

.activation-form .auth-button {
  width: 100%;
  background: $primary-button;
  text-transform: capitalize;
  line-height: normal;
  font-size: 18px;
  padding: 14.5px 0px;
  border-radius: 8px;

  @include for-mobile-phone-only {
    padding: 15.5px 0;
    font-size: 16px;
    border-radius: 12px;
    margin-bottom: 21px;
  }

  &:hover {
    background-color: $primary-button;
  }
}
.activation-form .MuiIconButton-root:hover {
    background-color: inherit
}

.otp-field .MuiOutlinedInput-root {
  border-radius: 6px !important;
  background-color: $grey;
}

.mobile-helpline-drawer .MuiDrawer-paper {
  max-width: 297px;
  width: 100%;
}

.tab-hamburger-menu .MuiDrawer-paper {
  max-width: 292px;
  width: 100%;
  background-color: #f6f8f9;

  @media (max-width: 688px) {
    max-width: 297px;
  }
}

// overriding default margins
p,
h3,
h4 {
  margin: 0;
}

.corporate-selection-user-details-button-container
  .sign-out-btn.MuiButton-root {
  height: 35px;
  width: 100px;
  border: 1px solid #c4bfe1;
  border-radius: 8px;
  background-color: #f0eefd;
  font-size: 16px;
  font-weight: normal;
  color: $primary-button;
  position: absolute;
  top: 12px;
  right: 20px;
  cursor: pointer;
  text-transform: none;
}

.tab-hamburger-drawer-btn .MuiButton-root {
  background-color: #f0eefd;
  &:hover {
    background-color: transparent;
  }
  &:active {
    background-color: transparent;
  }
}

//over-riding carousel styles

.carousel .control-dots .dot {
  margin: 0 2px;
  height: 10px;
  width: 10px;
}

.carousel .slide img {
  @media (max-width: $sm) {
    height: 130px;
  }
}

.carousel-root {
  direction: initial; // arabic fix for slider
}
// over-riding filter dropdown
.MuiInputLabel-root.Mui-focused.MuiInputLabel-root.Mui-focused {
  color: black;
}
// over-riding border color of the filter inputs on dashboard
.dashboard-filters-container .MuiOutlinedInput-notchedOutline {
  border-color: $ptag-color !important;
  border-radius: 8px;
}

.dashboard-filters-container .MuiOutlinedInput-input {
  padding: 18.5px 14px;
}
// over-riding border of filter search on tab devices
.filter-container-search .MuiOutlinedInput-notchedOutline {
  border: none;
}

.filter-container-search .MuiOutlinedInput-input {
  height: 17px;
}

.MuiSvgIcon-root.MuiSelect-icon {
  color: black;
}

// Custom styles for the flag images
.MuiMenuItem-root {
  display: flex;
  align-items: center;

  img {
    height: 24px;
    width: 36px;
    margin-right: 8px;
  }
}

// to be removed when changing select to divs in web filters
.MuiOutlinedInput-input.MuiSelect-select {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  @include for-tablet-device-only {
    font-size: 12px;
    font-weight: normal;
    color: rgb(80, 76, 76);
  }
}

.dashboard-filters-container .MuiInputBase-root {
  img {
    height: 24px;
    width: 36px;
    margin-right: 8px;
    @include rtl-styles {
      margin-left: 8px;
    }
  }
}

.expander-tabs .MuiTab-root {
  @include rtl-styles {
    padding: 0;
  }
}

//profile screen overriding

.profile-container .MuiTextField-root {
  height: 49px;
  width: 302px;
  @media (max-width: $md) {
    width: 285px;
  }
  @media (max-width: $sm) {
    max-width: 274px;
    width: 100%;
  }
}

.profile-container
  .MuiInputLabel-root.Mui-focused.MuiInputLabel-root.Mui-focused {
  color: #707070;
}

.profile-container .MuiOutlinedInput-input {
  border-radius: 8px;
}

.profile-container .MuiOutlinedInput-root {
  border-radius: 10px;
}

// removing the primary color from border
.profile-container
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: $grey;
}

// overriding the notifier to right section
.snackbar-component .MuiSnackbar-root {
  @media (min-width: 768px) and (max-width: 1064px) {
    position: absolute !important;
    top: 10px;
  }
  @media (min-width: 1064px) {
    position: absolute !important;
    top: 5px;
  }
}

.confirm-password-eye .MuiIconButton-root.MuiIconButton-root {
  @include for-mobile-phone-only {
    z-index: 99;
    margin-right: 0;
  }
}

// overiding the buttons present in the payment success page

.payment-success-continue-shopping-button.MuiButton-root {
  height: 50px;
  width: 180px;
  padding: 12.5px 3px;
  border-radius: 5px;
  border: 1px solid $primary-button;
  color: $primary-button;
  font-size: 16px;
  line-height: 1.25;
  text-transform: capitalize;
  &:hover {
    background-color: $white;
    border: 1px solid $primary-button;
  }
  @media (max-width: $md) {
    max-width: 314px;
    width: 100%;
    border-radius: 12px;
    font-weight: 600;
  }
}

.payment-success-view-giftcard-button.MuiButton-root {
  height: 50px;
  width: 180px;
  padding: 12.5px;
  border-radius: 5px;
  background-color: $primary-button;
  font-size: 16px;
  text-transform: capitalize;
  &:hover {
    background-color: $primary-button;
  }
  @media (max-width: $md) {
    max-width: 314px;
    width: 100%;
    border-radius: 12px;
    font-weight: 600;
  }
}

.history-container .MuiStack-root {
  padding-top: 0;
  overflow: unset;
}

.history-container .MuiOutlinedInput-input {
  padding: 18.5px 14px;
  text-transform: lowercase;
  color: $ptag-color;
}

.history-container .MuiStack-root > .MuiTextField-root {
  width: 300px;
  @media (max-width: $md) {
    width: 250px;
  }
  @media (max-width: $sm) {
    width: 275px;
  }
  @media (max-width: 375px) {
    width: 270px;
  }
}

.history-container
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: $grey;
}

.history-container
  .MuiOutlinedInput-root:focus-within
  .MuiOutlinedInput-notchedOutline {
  border-color: $grey;
}

.history-container
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: $grey;
}

.history-container .MuiOutlinedInput-root {
  border-radius: 8px;
}

.history__date-picker .MuiInputAdornment-root {
  button {
    padding: 0;
    border: none;
    img {
      height: 20px;
      width: 18px;
      cursor: pointer;
    }
  }
}

.history-container .MuiTableHead-root {
  background-color: $table-header-color;
}

.redemption-history-container .MuiStack-root {
  padding-top: 0;
  overflow: unset;
}

.redemption-history-container .MuiOutlinedInput-input {
  padding: 18.5px 14px;
  text-transform: lowercase;
  color: $ptag-color;
}

.redemption-history-container .MuiStack-root > .MuiTextField-root {
  width: 300px;
  @media (max-width: $md) {
    width: 250px;
  }
  @media (max-width: $sm) {
    width: 275px;
  }
  @media (max-width: 375px) {
    width: 270px;
  }
}

.redemption-history-container
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: $grey;
}

.redemption-history-container
  .MuiOutlinedInput-root:focus-within
  .MuiOutlinedInput-notchedOutline {
  border-color: $grey;
}

.redemption-history-container
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: $grey;
}

.redemption-history-container .MuiOutlinedInput-root {
  border-radius: 8px;
}

.redemption-history-container .MuiTableHead-root {
  background-color: #fafafa;
}

.credit-table-contents .MuiTableCell-root {
  font-size: 14px;

  @media (max-width: $sm) {
    font-size: 12px;
  }
}

.credit-table-body .MuiTableCell-root {
  font-size: 14px;
  @media (max-width: $sm) {
    font-size: 10px;
    font-weight: 500;
  }
}

.redemption-table-contents .MuiTableCell-root {
  font-size: 14px;
  font-weight: 600;
  padding-right: 13px;
  @media (max-width: $sm) {
    font-size: 12px;
  }
}

.redemption-table-body .MuiTableCell-root {
  font-size: 14px;
  font-weight: unset;
  @media (max-width: $sm) {
    font-size: 10px;
    font-weight: 500;
  }
}

.MuiToolbar-root {
  background-color: $table-header-color;
}

.MuiPickersDay-root.Mui-selected {
  background-color: $primary-button !important;
}

.brand-detail-accordion {
  width: 496px;
  @media (max-width: $md) {
    max-width: 609px;
    width: 100%;
    margin-top: 40px;
  }
}

.brand-detail-accordion .MuiPaper-root {
  border-radius: 14px !important;
  margin-bottom: 20px;
  position: unset;
  background-color: $brand-card-wrapper-color;
  box-shadow: none;
}

.brand-detail-accordion .MuiAccordionSummary-expandIconWrapper {
  img {
    height: 7px;
    width: 10px;
    color: $primary-button;
  }
}

.brand-detail-accordion .MuiAccordionSummary-root {
  padding: 6px 16px;
}

.brand-detail-accordion .MuiTypography-root {
  color: $primary-button;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
}
.brand-detail-accordion .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}

.brand-detail-accordion .MuiAccordionSummary-root.Mui-expanded {
  min-height: unset;
  padding-top: 18px;
}

.brand-detail-accordion .MuiAccordionDetails-root .MuiTypography-root {
  color: black;
  font-size: 12px;
  line-height: 2;
  font-weight: normal;
}

.brand-detail-accordion .MuiAccordionDetails-root {
  word-break: break-word;
}

.gifts-footer-separator {
  margin-top: 50px;
}

 .history-container .MuiOutlinedInput-notchedOutline{
  border: 1px solid $ptag-color !important ;
}

.credit-table-contents {
  @media (max-width: $sm) {
    max-width: 275px;
    overflow-x: scroll;
  }
  @media (max-width: 375px) {
    width: 269px;
  }
}

 .redemption-history-container .icon-button.MuiIconButton-root:hover{
   background-color: transparent;
}
 .redemption-history-container .MuiOutlinedInput-notchedOutline{
  border: 1px solid $ptag-color !important ;
}

.redemption-table-contents {
  @media (max-width: $md) {
    max-width: 569px;
    overflow-x: scroll;
  }
  @media (max-width: $sm) {
    max-width: 275px;
    overflow-x: scroll;
  }
  @media (max-width: 375px) {
    width: 269px;
  }
}
.redemption-history-container .redemption-table-body .view-button{
  padding-left: 6px;
}

.credit-date {
  max-width: 100px;
  white-space: nowrap;
}
.expiry-date {
  max-width: 100px;
  white-space: nowrap;
}
.checkout-modal-container {
  position: fixed;
  z-index: 1010 !important;
}

.resend-modal-container {
  position: fixed;
  z-index: 1010 !important;
}

.corporate-header-skeleton .MuiAppBar-root {
  background-color: #fff;
}

.corporate-header-skeleton .MuiToolbar-root {
  max-width: 1260px;
  margin: 0 auto;
}

.accordion-locations {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-locations-contact {
  display: flex;
  align-items: center;
  gap: 10px;
}

.accordion-locations-contact {
  color: $primary-button;
}

.table-header {
  > th {
    font-weight: 600 !important ;
  }
}

.credit-table-promotion {
  max-width: 303px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.brand-detail-accordion .about-brand-details {
  @media (min-width: 1024px) {
    max-height: 277px;
    overflow: scroll;
  }
  @media (max-width: 1024px) {
    max-height: 277px;
    overflow: scroll;
  }
  @media (max-width: 767px) {
    max-height: 237px;
    overflow: scroll;
  }
}

.accordion-locations-address {
  @media (max-width: 767px) {
    width: 185px;
  }
  @media (max-width: 425px) {
    width: 145px;
  }

  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.view-giftcard-accordion .MuiPaper-root {

  @media (max-width: $sm) {
    margin-bottom: 0;
    border-radius: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.view-giftcard-accordion .MuiAccordionSummary-root {
  @media (max-width: $sm) {
    padding: 6px 0px;
  }
}

.view-giftcard-accordion .MuiAccordionDetails-root {
  @media (max-width: $sm) {
    padding: 8px 0 16px   !important;
  }
  
}
