@import "../../../../styles/mixins";
@import "../../../../styles/variables";

.gift-container {
  background-color: white;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(4, 297px);
  width: 1260px;
  margin-bottom: 20px;

  @media (max-width: $lg) {
    grid-template-columns: repeat(3, 297px);
    max-width: 939px;
  }

  @media (max-width: $md) {
    grid-template-columns: repeat(2, 292px);
    max-width: 608px;
  }

  @media (max-width: $sm) {
    grid-template-columns: repeat(2, 163.5px);
    width: 342px;
    gap: 16px;
  }

  @media (max-width: 375px) {
    gap: 10px;
  }

  &__box {
    display: flex;
    justify-content: center;
    max-width: 1260px;

    &__card {
     
      width: 100%;
      height: 305px;
      border-radius: 12px;
      box-shadow: 0 1px 27px 0 rgba(22, 69, 135, 0.1);
      cursor: pointer;
      padding: 17.5px 16px;
      position: relative;
      transition: border-color 0.5s, transform 0.5s;
      @media (max-width: $sm) {
        box-shadow: unset;
        padding: 0;
        height: unset;
      }
      &:hover {
    border: 1px solid $primary-button;
    @media (max-width: $sm) {
      border:none;
    }
    transform: scale(1.02); 
  }

      &__logo-box {
        min-width: 100%;
        height: 168px;
        border: 1px solid #e6e6e6;
        border-radius: 12px;
        margin-bottom: 16px;
        img {
          max-width: 265px;
          max-height: 168px;
          object-fit: contain;
          border-radius: 12px;
        }
        @media (max-width: $sm) {
          height: 104px;
          img {
            max-width: 163.5px;
            max-height: 104px;
          }
        }
      }

      &__offers {
        background-color: #f20a0a;
        height: 24px;
        width: 154px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        padding: 4.5px 8px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        color: $white;
        position: absolute;
        top: 57%;
        left: 24%;

        @media (max-width: $sm) {
          display: none;
        }

        img {
          height: 15px;
          width: 15px;
        }

        p {
          font-size: 12px;
          font-weight: 800;
        }
      }

      &__brand-name {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
        text-align: left;
        max-width: 261px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
      }

      &__brand-type {
        font-size: 14px;
        color: $ptag-color;
        margin-bottom: 16px;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
       
      }

      &__online-friendly {
        max-width: 91px;
        height: 22px;
        border: 1px solid #b800c4;
        border-radius: 24px;
        font-size: 10px;
        padding: 2px 12.5px;
        white-space: nowrap;
        color: #b800c4;
      }
    }
  }
}
