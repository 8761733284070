@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.view-giftcard-container {
  display: flex;
  padding: 30px 90px;
  gap: 50px;
  justify-content: center;

  &__left-section {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__card-wrapper {
      width: 496px;
      height: 553px;
      padding: 20px 40px;
      background-color: $brand-card-wrapper-color;
      border-radius: 14px;

      &__info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        &__validity {
          font-size: 14px;
          line-height: 1.71;
          color: $primary-button;
          strong {
            font-weight: 500;
          }
        }
        &__in-store {
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid $orange;
          border-radius: 6px;
          font-size: 12px;
          font-weight: 500;
          background-color: $white;
          color: $store-button-text;
          padding: 12px;
        }
      }
      &__logo {
        img {
          height: 266px;
          width: 416px;
          object-fit: contain;
          border-radius: 14px;
        }
      }
      &__details{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        &__price{
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 34px;
        font-weight: 600;
        color: $primary-button;
        }

        &__barcode{    
        mix-blend-mode: darken;
        position: relative;
        &__digit{
          font-size: small;
          bottom: -9px;
          left:30%;
          position: absolute;
        }
        }
        &__pin{
          margin-top: 10.6px;
          font-size: 14px;
          &__text{

            color: $pin-color;
          }

          &__value{
            color: $primary-button;
          }
        }
      }
    }
    &__accordions{
      
    width: 714px;
    }
  }
}

