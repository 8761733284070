@import "../../../styles/mixins";
@import "../../../styles/variables";

.page-loader {
  position: relative;
  min-height: 100vh;
  background-color: $white;
}

.ygag-loading-logo {
  height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
