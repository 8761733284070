@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';

.header-container {
  height: 100px;
  width: 100%;
  padding: 25px 90px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  position: relative;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 1260px;
    width: 100%;

    &__left {
      display: flex;
      align-items: center;
      gap: 19px;
      &__stores {
        display: flex;
        gap: 5px;
        align-items: center;
        cursor: pointer;
        position: relative;
        &__flag{
          margin-right: 20px;
        }
      }
    }

    &__right {
      display: flex;

      &__history {
        display: flex;
        align-items: center;
        gap: 15px;
      }
      &__account-balance {
        display: flex;
        align-items: center;
        margin: 0 10px;
        &__wallet-icon {
          height: 40px;
          width: 40px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 10px;
        }
      }

      &__cart {
        display: flex;
        align-items: center;
        margin-right: 17px;
      }
    }
  }
}
